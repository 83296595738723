import * as yup from "yup";
interface Props {
  labelContent: string;
  type: string;
  setSelectValue: React.Dispatch<
    React.SetStateAction<{
      doctorStatus: {
        label: string;
        value: boolean;
      };
      numberPWZ: string;
      regonNumber: string;
      bookNumber: string;
      startDate: string;
      endDate: string;
      specjalization: {
        label: string;
        value: number;
        class: number;
      };
      insuranceExtensionValue: {
        label: string;
        amount: number;
        contribution: number;
      };
      doubleAmountAndContribution: boolean;
      chirurgicProcedure: boolean;
      insuranceType: string;
      sublimit: {
        label: string;
        amount: number;
        contribution: number;
      };
      insuranceExtension: boolean;
      pdf: boolean;
      emailAdress: string;
      agreement: boolean;

      agreement2: boolean;
      agreement3: boolean;
      agreement4: boolean;
      agreement5: boolean;
      agreement6: boolean;
      agreement7: boolean;
      agreement8: boolean;
      lawContext: boolean;
      sublimintAgreement: boolean;
    }>
  >;
  selectValue: any;
  currentDate?: string;
  maxDate?: string;
  inputLnth: number;
  pattern?: string;
}

export const Email = ({
  labelContent,
  type,
  selectValue,
  setSelectValue,
  currentDate,
  maxDate,
  inputLnth,
  pattern,
}: Props) => {
  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (labelContent) {
      case "Numer PWZ":
        return setSelectValue({ ...selectValue, numberPWZ: e.target.value });
      case "Początek ubezpieczenia":
        return setSelectValue({ ...selectValue, startDate: e.target.value });
      case "Koniec ubezpieczenia":
        return setSelectValue({ ...selectValue, endDate: e.target.value });
      case "Podaj email":
        return setSelectValue({ ...selectValue, emailAdress: e.target.value });
      case "Numer Regon własnej praktyki lekarskiej":
        return setSelectValue({ ...selectValue, regonNumber: e.target.value });
      case "Numer ksiegi rejestrowej praktyki lekarskiej":
        return setSelectValue({ ...selectValue, bookNumber: e.target.value });
    }
  };

  const validationEmail = () => {
    let emailAdress = yup.string().email().required();

    return emailAdress.isValidSync(selectValue.emailAdress);
  };

  return (
    <div>
      {" "}
      <div className="doctorStatus">
        <div className="label">
          <label className="label" htmlFor={labelContent}>
            {labelContent}
          </label>
        </div>

        <input
          maxLength={inputLnth}
          className={
            validationEmail()
              ? "input-normal-email"
              : "input-normal-validation-email"
          }
          max={maxDate}
          min={currentDate}
          id={labelContent}
          type={type}
          pattern={pattern}
          onChange={(e) => inputHandler(e)}
        ></input>
      </div>
    </div>
  );
};
