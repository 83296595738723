export const voluntaryRisk3 = {
  data: [
    {
      label: "Suma gwarancyjna = 100 000 zł składka roczna = 210 zł",
      amount: 100_000,
      contribution: 210,
    },
    {
      label: "Suma gwarancyjna = 200 000 zł składka roczna = 310 zł",
      amount: 200_000,
      contribution: 310,
    },
    {
      label: "Suma gwarancyjna = 300 000 zł składka roczna = 370 zł",
      amount: 300_000,
      contribution: 370,
    },
    {
      label: "Suma gwarancyjna = 400 000 zł składka roczna = 415 zł",
      amount: 400_000,
      contribution: 415,
    },
    {
      label: "Suma gwarancyjna = 500 000 zł składka roczna = 460 zł",
      amount: 500_000,
      contribution: 460,
    },
    {
      label: "Suma gwarancyjna = 600 000 zł składka roczna = 490 zł",
      amount: 600_000,
      contribution: 490,
    },
    {
      label: "Suma gwarancyjna = 800 000 zł składka roczna = 560 zł",
      amount: 800_000,
      contribution: 560,
    },
    {
      label: "Suma gwarancyjna = 1 000 000 zł składka roczna = 630 zł",
      amount: 1_000_000,
      contribution: 630,
    },
    {
      label: "Suma gwarancyjna = 2 000 000 zł składka roczna = 1300 zł",
      amount: 2_000_000,
      contribution: 1300,
    },
    {
      label: "Suma gwarancyjna = 3 000 000 zł składka roczna = 1680 zł",
      amount: 3_000_000,
      contribution: 1680,
    },
    {
      label: "Suma gwarancyjna = 4 000 000 zł składka roczna = 2058 zł",
      amount: 4_000_000,
      contribution: 2058,
    },
    {
      label: "Suma gwarancyjna = 5 000 000 zł składka roczna = 2800 zł",
      amount: 5_000_000,
      contribution: 2800,
    },
  ],
};
