export const voluntary = {
  data: [
    {
      label: "Suma gwarancyjna = 100 000 zł składka roczna = 50 zł",
      amount: 100_000,
      contribution: 50,
    },
    {
      label: "Suma gwarancyjna = 200 000 zł składka roczna = 73 zł",
      amount: 200_000,
      contribution: 73,
    },
    {
      label: "Suma gwarancyjna = 300 000 zł składka roczna = 86 zł",
      amount: 300_000,
      contribution: 86,
    },
    {
      label: "Suma gwarancyjna = 400 000 zł składka roczna = 99 zł",
      amount: 400_000,
      contribution: 99,
    },
    {
      label: "Suma gwarancyjna = 500 000 zł składka roczna = 110 zł",
      amount: 500_000,
      contribution: 110,
    },
    {
      label: "Suma gwarancyjna = 600 000 zł składka roczna = 120 zł",
      amount: 600_000,
      contribution: 120,
    },
    {
      label: "Suma gwarancyjna = 800 000 zł składka roczna = 135 zł",
      amount: 800_000,
      contribution: 135,
    },
    {
      label: "Suma gwarancyjna = 1 000 000 zł składka roczna = 150 zł",
      amount: 1_000_000,
      contribution: 150,
    },
    {
      label: "Suma gwarancyjna = 2 000 000 zł składka roczna = 320 zł",
      amount: 2_000_000,
      contribution: 320,
    },
    {
      label: "Suma gwarancyjna = 3 000 000 zł składka roczna = 410 zł",
      amount: 3_000_000,
      contribution: 410,
    },
    {
      label: "Suma gwarancyjna = 4 000 000 zł składka roczna = 500 zł",
      amount: 4_000_000,
      contribution: 500,
    },
    {
      label: "Suma gwarancyjna = 5 000 000 zł składka roczna = 619 zł",
      amount: 5_000_000,
      contribution: 619,
    },
  ],
};
