export const insuranceExtension2 = {
  data: [
    {
      label: "sublimit 50 000 zł - składka roczna 35 zł",
      amount: 50000,
      contribution: 35,
    },
    {
      label: "sublimit 100 000 zł - składka roczna 105 zł",
      amount: 100000,
      contribution: 105,
    },
    {
      label: "sublimit 150 000 zł - składka roczna 165 zł",
      amount: 150000,
      contribution: 165,
    },
  ],
};
