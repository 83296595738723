import clsx from "clsx";
import { JsxElement } from "typescript";
interface Props {
  labelContent: string;
  direction: string;
  setSelectValue: React.Dispatch<
    React.SetStateAction<{
      doctorStatus: {
        label: string;
        value: boolean;
      };
      numberPWZ: string;
      regonNumber: string;
      bookNumber: string;
      startDate: string;
      endDate: string;
      specjalization: {
        label: string;
        value: number;
        class: number;
      };
      chirurgicProcedure: boolean;
      insuranceType: string;
      sublimit: {
        label: string;
        amount: number;
        contribution: number;
      };
      doubleAmountAndContribution: boolean;
      insuranceExtension: boolean;
      pdf: boolean;
      emailAdress: string;
      agreement: boolean;
      insuranceExtensionValue: {
        label: string;
        amount: number;
        contribution: number;
      };

      agreement2: boolean;
      agreement3: boolean;
      agreement4: boolean;
      agreement5: boolean;
      agreement6: boolean;
      agreement7: boolean;
      agreement8: boolean;
      lawContext: boolean;
      sublimintAgreement: boolean;
    }>
  >;
  selectValue: any;
  currentValue: boolean;
  anchor: JSX.Element | string;
}
export const CheckInput = ({
  labelContent,
  direction,
  setSelectValue,
  selectValue,
  currentValue,
  anchor,
}: Props) => {
  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (labelContent) {
      case "Lekarz wykonuje zabiegi chirurgiczne, endoskopowe lub z zakresu radiologii interwencyjnej":
        if (selectValue.insuranceType === "Ubezpieczenia dobrowolne") {
          return setSelectValue({
            ...selectValue,
            chirurgicProcedure: e.target.checked,
          });
        } else {
          return setSelectValue({
            ...selectValue,
            chirurgicProcedure: e.target.checked,
          });
        }

      case "Oświadczam, że przeczytałem i rozumiem treść klauzuli ochrony prawnej, w szczególności obowiązki informacyjne wobec ubezpieczyciela.":
        return setSelectValue({ ...selectValue, lawContext: e.target.checked });
      case "Rozszerzenie ochrony o szkody powstałe wskutek zabiegów z zakresu medycyny estetycznej / chirurgii plastycznej (+100% składki)":
        return setSelectValue({
          ...selectValue,
          insuranceExtension: e.target.checked,
        });
      case "Podwój sumę gwarancyjną (+100% składki)":
        return setSelectValue({
          ...selectValue,
          doubleAmountAndContribution: e.target.checked,
        });
      case "Rozumiem że sublimit dla zabiegów z zakresu medycyny estetycznej/ chirurgi plastycznej udzielanych w celach estetycznych wynosi 500.000 PLN":
        return setSelectValue({
          ...selectValue,
          sublimintAgreement: e.target.checked,
        });
      case "Prosze o przesłanie mi certfikatu (pdf) wyłącznie drogą elektroniczną (e-mail). Certyfikat NIE ZOSTANIE wysłany tradycyjną pocztą":
        return setSelectValue({ ...selectValue, pdf: e.target.checked });
      case `Rozumiem, iż warunkiem objęcia ubezpieczeniem od dnia ${selectValue.startDate}  jest wpłata składki najpóźniej w dniu ${selectValue.startDate} . W przypadku późniejszej wpłaty okres ubezpieczenia rozpocznie się w dniu dokonania tej wpłaty`:
        return setSelectValue({ ...selectValue, agreement: e.target.checked });
      case "Oświadczam, iż przed zawarciem umowy ubezpieczenia otrzymałem(am) i zapoznałem(am) się z: OWU (link) oraz z informacjami o produkcie ubezpieczeniowym (link). Pobranie tych dokumentów jest warunkiem przystąpienia do ubezpieczenia.":
        return setSelectValue({ ...selectValue, agreement2: e.target.checked });
      case "Oświadczam, że otrzymałam/em informację o przetwarzaniu moich danych osobowych, zawartą w dostarczonym mi dokumencie Informacja o Administratorze danych osobowych  (link).":
        return setSelectValue({ ...selectValue, agreement3: e.target.checked });
      case "Oświadczam, że przed zawarciem umowy ubezpieczenia uzyskałem/am informację, że do umowy ubezpieczenia mają zastosowanie przepisy prawa polskiego.":
        return setSelectValue({ ...selectValue, agreement4: e.target.checked });
      case "Oświadczam, że niniejsza umowa ubezpieczenia spełnia moje wymagania i potrzeby przy uwzględnieniu wysokości składki jaką jestem skłonny/a zapłacić. Zawieram ją w wyniku mojej świadomej decyzji.":
        return setSelectValue({ ...selectValue, agreement5: e.target.checked });
      case "Wyrażam zgodę na przesyłanie informacji i dokumentów, w tym OWU oraz dokumentu zawierającego informacje o produkcie, a także informacji, o których mowa w art. 9 ust.1 ustawy o dystrybucji ubezpieczeń, które mają związek z wnioskowaną lub zawartą umową ubezpieczenia w STU Ergo Hestia SA za pośrednictwem środków komunikacji elektronicznej, w tym wiadomości e-mail, na wskazany przeze mnie adres mailowy. W razie zmiany podanych przeze mnie danych kontaktowych, zobowiązuję się uaktualniać moje dane, dzięki którym będę otrzymywać informacje za pośrednictwem środków komunikacji elektronicznej.":
        return setSelectValue({ ...selectValue, agreement6: e.target.checked });
      case "Wyrażam zgodę na przetwarzanie danych osobowych, podanych w formularzu/wniosku o ubezpieczenie, przez Lewczuk Sp. z o.o., KRS 0000206033 (jako administratora danych) w celu przesyłania informacji i dokumentów za pośrednictwem środków komunikacji elektronicznej niezbędnych do zawarcia, wykonywania (obsługi) i odnowienia umowy ubezpieczenia, w tym: - kontaktu poprzez email (poczta elektroniczna), - kontaktu poprzez sms i telefon (wiadomości tekstowe i połączenia głosowe). Zgody powyższej udzielam dobrowolnie i mogę odwołać ją w każdym czasie wysyłając e-mail na adres biuro@lewczuk.biz, listem na adres Lewczuk Sp. z o.o., Brzoskwiniowa 1, 20-142 Lublin albo na stronie internetowej www.lewczuk.biz. Wycofanie zgody wywiera skutki prawne od dnia jej wycofania.":
        return setSelectValue({ ...selectValue, agreement7: e.target.checked });
      case "Upoważniam brokera ubezpieczeniowego Lewczuk Sp. z o.o., zezwolenie PUNU nr 187/97 do wykonywania czynności pośrednictwa ubezpieczeniowego na moją rzecz przy zawieraniu i wykonywaniu wnioskowanej przeze mnie umowy ubezpieczenia odpowiedzialności cywilnej":
        return setSelectValue({ ...selectValue, agreement8: e.target.checked });
    }
  };

  return (
    <div
      className={clsx(
        direction === "column" && "checkboxContainer-column",
        direction === "row" && "checkboxContainer-row"
      )}
    >
      <div
        className={clsx(
          direction === "column" && "labelContainer",
          direction === "row" && "labelContainer-row"
        )}
      >
        <label
          htmlFor={labelContent}
          className={clsx(
            direction === "column" && "input-label-column",
            direction === "row" && "input-label-row"
          )}
        >
          {anchor}
        </label>
      </div>
      <input
        id={labelContent}
        disabled={
          selectValue.specjalization.label ===
            "Chirurgia plastyczna grupa ryzyka 3" &&
          labelContent ===
            "Rozszerzenie ochrony o szkody powstałe wskutek zabiegów z zakresu medycyny estetycznej / chirurgii plastycznej (+100% składki)"
            ? true
            : false
        }
        type="checkbox"
        checked={currentValue}
        onChange={(e) => inputHandler(e)}
      ></input>
    </div>
  );
};
