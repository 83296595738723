export const insuranceExtension4 = {
  data: [
    {
      label: "brak",
      amount: 0,
      contribution: 0,
    },
    {
      label: "sublimit 50 000 zł - składka roczna 70 zł",
      amount: 50000,
      contribution: 70,
    },
    {
      label: "sublimit 100 000 zł - składka roczna 130 zł",
      amount: 100000,
      contribution: 130,
    },
  ],
};
