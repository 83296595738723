export const insurance = {
  data: [
    {
      label: "Ubezpieczenie obowiązkowe",
      class: 1,
    },
    {
      label: "Ubezpieczenia dobrowolne",
      class: 2,
    },
  ],
};
