import * as yup from "yup";
import { lightFormat, add, differenceInDays, getMonth } from "date-fns";
import isLeapYear from "date-fns/isLeapYear";
interface Props {
  labelContent: string;
  type: string;
  setSelectValue: React.Dispatch<
    React.SetStateAction<{
      doctorStatus: {
        label: string;
        value: boolean;
      };
      numberPWZ: string;
      regonNumber: string;
      bookNumber: string;
      startDate: string;
      endDate: string;
      specjalization: {
        label: string;
        value: number;
        class: number;
      };
      insuranceExtensionValue: {
        label: string;
        amount: number;
        contribution: number;
      };
      chirurgicProcedure: boolean;
      insuranceType: string;
      sublimit: {
        label: string;
        amount: number;
        contribution: number;
      };
      doubleAmountAndContribution: boolean;
      insuranceExtension: boolean;
      pdf: boolean;
      emailAdress: string;
      agreement: boolean;

      agreement2: boolean;
      agreement3: boolean;
      agreement4: boolean;
      agreement5: boolean;
      agreement6: boolean;
      agreement7: boolean;
      agreement8: boolean;
      lawContext: boolean;
      sublimintAgreement: boolean;
    }>
  >;

  selectValue: any;
  currentDate?: string;
  maxDate?: string;
  inputLnth: number;
  pattern?: string;
}

export const TextInput = ({
  labelContent,
  type,
  selectValue,
  setSelectValue,
  currentDate,
  maxDate,
  inputLnth,
  pattern,
}: Props) => {
  const inputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    switch (labelContent) {
      case "Numer PWZ":
        return setSelectValue({ ...selectValue, numberPWZ: e.target.value });
      case "Początek ubezpieczenia":
        if (selectValue.startDate === "2024-01-01") {
          return setSelectValue({
            ...selectValue,
            startDate: e.target.value,
            endDate: lightFormat(
              add(new Date(e.target.value), { days: 365 }),
              "y-MM-dd"
            ),
          });
        }
        let variable = lightFormat(
          add(new Date(e.target.value), { days: 365 }),
          "y-MM-dd"
        );

        if (
          isLeapYear(new Date(variable)) &&
          getMonth(new Date(selectValue.endDate)) > 1
        ) {
          return setSelectValue({
            ...selectValue,
            startDate: e.target.value,
            endDate: lightFormat(
              add(new Date(e.target.value), { days: 365 }),
              "y-MM-dd"
            ),
          });
        } else {
          return setSelectValue({
            ...selectValue,
            startDate: e.target.value,
            endDate: lightFormat(
              add(new Date(e.target.value), { days: 365 }),
              "y-MM-dd"
            ),
          });
        }
      case "Koniec ubezpieczenia":
        return setSelectValue({ ...selectValue, endDate: e.target.value });

      case "Podaj email":
        return setSelectValue({ ...selectValue, emailAdress: e.target.value });
      case "Numer Regon własnej praktyki lekarskiej":
        return setSelectValue({ ...selectValue, regonNumber: e.target.value });
      case "Numer ksiegi rejestrowej praktyki lekarskiej":
        return setSelectValue({ ...selectValue, bookNumber: e.target.value });
    }
  };
  const validatePWZ = (pwzNumber: string) => {
    let array = pwzNumber.split("");

    let arrayM = [1, 2, 3, 4, 5, 6];
    let sum = 0;
    for (let i = 0; i < 6; i++) {
      sum += Number(array[i + 1]) * arrayM[i];
    }

    if (sum % 11 === Number(array[0])) {
      return true;
    } else {
      return false;
    }
  };

  const validateRegon = (regon: string) => {
    let array = regon.split("");
    let arrayM = [8, 9, 2, 3, 4, 5, 6, 7];
    let sum = 0;
    for (let i = 0; i < 8; i++) {
      sum += Number(array[i]) * arrayM[i];
    }
    if (sum % 11 === 10) {
      if (0 === Number(array[8])) {
        return true;
      } else {
        return false;
      }
    }
    if (sum % 11 === Number(array[8])) {
      return true;
    } else {
      return false;
    }
  };

  const getCorrectValidations = () => {
    switch (labelContent) {
      case "Numer PWZ":
        return validatePWZ(selectValue.numberPWZ);

      case "Początek ubezpieczenia":
        let startDate = yup.string().required();
        return startDate.isValidSync(selectValue.startDate);
      case "Koniec ubezpieczenia":
        let endDate = yup.string().required();

        return endDate.isValidSync(selectValue.endDate);
      case "Podaj email":
        let emailAdress = yup.string().email().required();
        return emailAdress.isValidSync(selectValue.emailAdress);
      case "Numer Regon własnej praktyki lekarskiej":
        return validateRegon(selectValue.regonNumber);

      case "Numer ksiegi rejestrowej praktyki lekarskiej":
        let bookNumber = yup.string().required().length(12);
        return bookNumber.isValidSync(selectValue.bookNumber);
    }
  };
  const getValue = () => {
    switch (labelContent) {
      case "Numer PWZ":
        return selectValue.numberPWZ;
      case "Początek ubezpieczenia":
        return selectValue.startDate;
      case "Koniec ubezpieczenia":
        return selectValue.endDate;
      case "Podaj email":
        return selectValue.emailAdress;
      case "Numer Regon własnej praktyki lekarskiej":
        return selectValue.regonNumber;
      case "Numer ksiegi rejestrowej praktyki lekarskiej":
        return selectValue.bookNumber;
    }
  };

  return (
    <div className="inputContainer">
      <div className="labelContainer">
        <label className="input-label" htmlFor={labelContent}>
          {labelContent}
        </label>
      </div>

      <input
        maxLength={inputLnth}
        className={
          getCorrectValidations() ? "input-normal" : "input-normal-validation"
        }
        max={lightFormat(
          add(new Date(selectValue.startDate), { days: 365 }),
          "y-MM-dd"
        )}
        min="2024-01-01"
        id={labelContent}
        type={type}
        value={getValue()}
        pattern={pattern}
        onChange={(e) => inputHandler(e)}
        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
      ></input>
    </div>
  );
};
