export const voluntaryRisk2 = {
  data: [
    {
      label: "Suma gwarancyjna = 100 000 zł składka roczna = 100 zł",
      amount: 100_000,
      contribution: 100,
    },
    {
      label: "Suma gwarancyjna = 200 000 zł składka roczna = 150 zł",
      amount: 200_000,
      contribution: 150,
    },
    {
      label: "Suma gwarancyjna = 300 000 zł składka roczna = 175 zł",
      amount: 300_000,
      contribution: 175,
    },
    {
      label: "Suma gwarancyjna = 400 000 zł składka roczna = 200 zł",
      amount: 400_000,
      contribution: 200,
    },
    {
      label: "Suma gwarancyjna = 500 000 zł składka roczna = 225 zł",
      amount: 500_000,
      contribution: 225,
    },
    {
      label: "Suma gwarancyjna = 600 000 zł składka roczna = 240 zł",
      amount: 600_000,
      contribution: 240,
    },
    {
      label: "Suma gwarancyjna = 800 000 zł składka roczna = 270 zł",
      amount: 800_000,
      contribution: 270,
    },
    {
      label: "Suma gwarancyjna = 1 000 000 zł składka roczna = 300 zł",
      amount: 1_000_000,
      contribution: 300,
    },
    {
      label: "Suma gwarancyjna = 2 000 000 zł składka roczna = 650 zł",
      amount: 2_000_000,
      contribution: 650,
    },
    {
      label: "Suma gwarancyjna = 3 000 000 zł składka roczna = 760 zł",
      amount: 3_000_000,
      contribution: 760,
    },
    {
      label: "Suma gwarancyjna = 4 000 000 zł składka roczna = 970 zł",
      amount: 4_000_000,
      contribution: 970,
    },
    {
      label: "Suma gwarancyjna = 5 000 000 zł składka roczna = 1490 zł",
      amount: 5_000_000,
      contribution: 1490,
    },
  ],
};
