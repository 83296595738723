import { useEffect, useState } from "react";
import { compulsoryinsurance } from "../data/compulsoryinsurance";
import { compulsoryinsurance2 } from "../data/compulsoryinsurance2";
import { compulsoryinsurance3 } from "../data/compulsoryinsurance3";
import { insurance2 } from "../data/insurance2";
import { insuranceExtension } from "../data/insuranceExtension";
import { insuranceExtension2 } from "../data/insuranceExtension2";
import { insuranceExtension3 } from "../data/insuranceExtension3";
import { insuranceExtension4 } from "../data/insuranceExtension4";
import { voluntaryRisk3 } from "../data/volunatryRisk3";
import { voluntary } from "../data/voluntary";
import { voluntaryRisk2 } from "../data/voluntaryRisk2";

import * as yup from "yup";
import { empty } from "../data/empty";
interface Props {
  labelContent: string;
  placeHolderContent: string;
  specjalizationData?: {
    data: {
      label: string;
      value: number;
      class: number;
    }[];
  };
  doctorStatus?: {
    data: {
      label: string;
      value: boolean;
    }[];
  };
  setSelectValue: React.Dispatch<
    React.SetStateAction<{
      doctorStatus: {
        label: string;
        value: boolean;
      };
      numberPWZ: string;
      regonNumber: string;
      bookNumber: string;
      startDate: string;
      endDate: string;
      specjalization: {
        label: string;
        value: number;
        class: number;
      };
      insuranceExtensionValue: {
        label: string;
        amount: number;
        contribution: number;
      };
      chirurgicProcedure: boolean;
      insuranceType: string;
      sublimit: {
        label: string;
        amount: number;
        contribution: number;
      };
      doubleAmountAndContribution: boolean;
      insuranceExtension: boolean;
      pdf: boolean;
      emailAdress: string;
      agreement: boolean;

      agreement2: boolean;
      agreement3: boolean;
      agreement4: boolean;
      agreement5: boolean;
      agreement6: boolean;
      agreement7: boolean;
      agreement8: boolean;
      lawContext: boolean;
      sublimintAgreement: boolean;
    }>
  >;
  calculateContribution: Function;
  selectValue: any;
  insurance: {
    data: {
      label: string;
      class: number;
    }[];
  };
  currentLabel: string;
}

export const SelectInput = ({
  labelContent,
  placeHolderContent,
  specjalizationData,
  doctorStatus,
  setSelectValue,
  selectValue,
  insurance,
  currentLabel,
  calculateContribution,
}: Props) => {
  const selectData = (labelContent: string) => {
    switch (labelContent) {
      case "Status lekarza":
        return doctorStatus;
      case "Faktycznie wykonywana specjalizaja o największym stopniu ryzyka":
        if (selectValue.doctorStatus.label === "") {
          return empty;
        } else {
          return specjalizationData;
        }

      case "Rodzaj ubezpieczenia":
        if (selectValue.specjalization.label !== "") {
          if (
            selectValue.doctorStatus.label ===
            "Wykonuje zawód lekarza (dentysty) w formie praktyki lekarskiej."
          ) {
            return insurance;
          } else {
            return insurance2;
          }
        } else {
          return empty;
        }

      case "Opcje ubezpieczenia":
        if (
          selectValue.type !== "" &&
          selectValue.specjalization.label !== ""
        ) {
          if (selectValue.insuranceType === "Ubezpieczenia dobrowolne") {
            if (selectValue.specjalization.class === 3) {
              return voluntaryRisk3;
            } else if (
              selectValue.specjalization.class === 2 ||
              selectValue.chirurgicProcedure
            ) {
              return voluntaryRisk2;
            } else {
              return voluntary;
            }
          } else {
            if (
              selectValue.specjalization.class === 2 ||
              selectValue.chirurgicProcedure
            ) {
              return compulsoryinsurance2;
            } else if (selectValue.specjalization.class === 3) {
              return compulsoryinsurance3;
            } else {
              return compulsoryinsurance;
            }
          }
        } else {
          return empty;
        }

      case "Rozszerzenie ubezpieczenia (klauzula ochrony prawnej)":
        if (
          selectValue.doctorStatus.label === "" ||
          selectValue.insuranceExtensionValue.label === "" ||
          selectValue.specjalization.label === "" ||
          selectValue.insuranceType === ""
        ) {
          return empty;
        } else {
          if (selectValue.insuranceType === "Ubezpieczenia dobrowolne") {
            if (selectValue.insuranceExtensionValue.amount === 50000) {
              return insuranceExtension3;
            }

            if (selectValue.insuranceExtensionValue.amount > 150000) {
              return insuranceExtension;
            }

            if (selectValue.insuranceExtensionValue.amount < 150000) {
              return insuranceExtension4;
            }
          } else {
            return insuranceExtension2;
          }
        }
    }
  };

  const selectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    selectData(labelContent)?.data.map((option) => {
      if (e.target.value === option.label) {
        switch (labelContent) {
          case "Status lekarza":
            // return setSelectValue((state) => ({
            //   ...state,
            //   doctorStatus: { ...state.doctorStatus, ...option },
            // }));
            if ("value" in option) {
              return setSelectValue((state) => ({
                ...state,
                doctorStatus: option,
              }));
            } else {
              return "";
            }

          case "Faktycznie wykonywana specjalizaja o największym stopniu ryzyka":
            if (option.label === "Chirurgia plastyczna grupa ryzyka 3") {
              return setSelectValue((state) => ({
                ...selectValue,
                specjalization: option,
                chirurgicProcedure: false,
                insuranceExtension: true,
              }));
            } else {
              return setSelectValue((state) => ({
                ...selectValue,
                specjalization: option,
                chirurgicProcedure: false,
              }));
            }

          case "Rodzaj ubezpieczenia":
            return setSelectValue((state) => ({
              ...state,
              insuranceType: e.target.value,
            }));

          case "Opcje ubezpieczenia":
            if ("contribution" in option) {
              return setSelectValue((state) => ({
                ...state,
                insuranceExtensionValue: option,
              }));
            } else {
              return setSelectValue({
                ...selectValue,
                insuranceExtensionValue: option,
              });
            }

          case "Rozszerzenie ubezpieczenia (klauzula ochrony prawnej)":
            return setSelectValue({
              ...selectValue,
              sublimit: option,
            });
        }
      }
    });
  };
  const getValidation = () => {
    switch (labelContent) {
      case "Status lekarza":
        let doctorStatus = yup.object().shape({
          label: yup.string().required(),
          value: yup.boolean(),
        });
        return doctorStatus.isValidSync(selectValue.doctorStatus);

      case "Faktycznie wykonywana specjalizaja o największym stopniu ryzyka":
        let specjalization = yup.object().shape({
          label: yup.string().required(),
          value: yup.number(),
          class: yup.number(),
        });
        return specjalization.isValidSync(selectValue.specjalization);
      case "Rodzaj ubezpieczenia":
        let insuranceType = yup.string().required();
        return insuranceType.isValidSync(selectValue.insuranceType);
      case "Opcje ubezpieczenia":
        let insuranceOption = yup.object().shape({
          label: yup.string().required(),
          amount: yup.number(),
          contribution: yup.number(),
        });
        return insuranceOption.isValidSync(selectValue.insuranceExtensionValue);
      case "Rozszerzenie ubezpieczenia (klauzula ochrony prawnej)":
        let sublimitValidation = yup.object().shape({
          label: yup.string(),
          value: yup.number(),
          class: yup.number(),
        });
        return sublimitValidation.isValidSync(selectValue.sublimit);
    }
  };

  return (
    <div className="doctorStatus">
      <label htmlFor={labelContent} className="label">
        {labelContent}
      </label>
      <select
        id={labelContent}
        className={getValidation() ? "" : "select-validation"}
        onChange={(e) => selectHandler(e)}
        value={currentLabel}
      >
        <option value="" disabled selected>
          {placeHolderContent}
        </option>
        {selectData(labelContent)?.data.map((option) => {
          return <option>{option.label}</option>;
        })}
      </select>
    </div>
  );
};
