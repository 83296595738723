export const specjalizationData = {
  data: [
    {
      label: "Lekarz bez specjalizacji grupa ryzyka 1",
      value: 1.31,
      class: 1,
    },
    {
      label: "Alergologia grupa ryzyka 1",
      value: 1.3101,
      class: 1,
    },
    {
      label: "Anestezjologia i intensywna terapia grupa ryzyka 3",
      value: 3.3102,
      class: 3,
    },
    {
      label: "Angiologia grupa ryzyka 1",
      value: 1.3103,
      class: 1,
    },
    {
      label: "audiologia i foniatria grupa ryzyka 1",
      value: 1.3104,
      class: 1,
    },
    {
      label: "Chirurgia dziecięca grupa ryzyka 3",
      value: 3.3106,
      class: 3,
    },
    {
      label: "Chirurgia klatki piersiowej grupa ryzyka 3",
      value: 3.3107,
      class: 3,
    },
    {
      label: "Chirurgia naczyniowa grupa ryzyka 3",
      value: 3.3108,
      class: 3,
    },
    {
      label: "Chirurgia ogólna grupa ryzyka 3",
      value: 3.3109,
      class: 3,
    },
    {
      label: "Chiururgia onkologiczna grupa ryzyka 3",
      value: 3.311,
      class: 3,
    },
    {
      label: "Chirurgia plastyczna grupa ryzyka 3",
      value: 3.3111,
      class: 3,
    },
    {
      label: "Chirurgia szczękowo-twarzowa grupa ryzyka 3",
      value: 3.3112,
      class: 3,
    },
    {
      label: "Choroby płuc grupa ryzyka 1",
      value: 1.3113,
      class: 1,
    },
    {
      label: "Choroby wewnętrzne grupa ryzyka 1",
      value: 1.3114,
      class: 1,
    },
    {
      label: "Choroby zakaźne grupa ryzyka 1",
      value: 1.3115,
      class: 1,
    },
    {
      label: "Dermatologia i wenerologia grupa ryzyka 1",
      value: 1.3116,
      class: 1,
    },
    {
      label: "Diabetologia grupa ryzyka 1",
      value: 1.3117,
      class: 1,
    },
    {
      label: "Diagnostyka laboratoryjna grupa ryzyka 1",
      value: 1.3118,
      class: 1,
    },
    {
      label: "Endokrynologia grupa ryzyka 1",
      value: 1.3119,
      class: 1,
    },
    {
      label: "Epidemiologia grupa ryzyka 1",
      value: 1.312,
      class: 1,
    },
    {
      label: "Farmakologia kliniczna grupa ryzyka 1",
      value: 1.3121,
      class: 1,
    },
    {
      label: "Gastroenterologia grupa ryzyka 1",
      value: 1.3122,
      class: 1,
    },
    {
      label: "Genetyka kliniczna grupa ryzyka 1",
      value: 1.3123,
      class: 1,
    },
    {
      label: "Geriatria grupa ryzyka 1",
      value: 1.3124,
      class: 1,
    },
    {
      label: "Hematologia grupa ryzyka 1",
      value: 1.3125,
      class: 1,
    },
    {
      label: "Immunologia kliniczna grupa ryzyka 1",
      value: 1.3126,
      class: 1,
    },
    {
      label: "Kardiochirurgia grupa ryzyka 3",
      value: 3.3127,
      class: 3,
    },
    {
      label: "Kardiologia grupa ryzyka 1",
      value: 1.3128,
      class: 1,
    },
    {
      label: "Kardiologia dziecka grupa ryzyka 1",
      value: 1.3129,
      class: 1,
    },
    {
      label: "Medycyna nuklearna grupa ryzyka 1",
      value: 1.313,
      class: 1,
    },
    {
      label: "Medycyna paliatywna grupa ryzyka 1",
      value: 1.3131,
      class: 1,
    },
    {
      label: "Medycyna pracy grupa ryzyka 1",
      value: 1.3132,
      class: 1,
    },
    {
      label: "Medycyna ratunkowa grupa ryzyka 3",
      value: 3.3133,
      class: 3,
    },
    {
      label: "Medycyna rodzinna grupa ryzyka 1",
      value: 1.3134,
      class: 1,
    },
    {
      label: "Medycyna sądowa grupa ryzyka 1",
      value: 1.3135,
      class: 1,
    },
    {
      label: "Medycyna sportowa grupa ryzyka 2",
      value: 2.3136,
      class: 2,
    },
    {
      label: "Medycyna transportu grupa ryzyka 1",
      value: 1.3137,
      class: 1,
    },
    {
      label: "Mikrobiologia lekarska grupa ryzyka 1",
      value: 1.3138,
      class: 1,
    },
    {
      label: "Nefrologia grupa ryzyka 1",
      value: 1.3139,
      class: 1,
    },

    {
      label: "Neurologia grupa ryzyka 1",
      value: 1.3142,
      class: 1,
    },
    {
      label: "Neurologia dziecięca grupa ryzyka 1",
      value: 1.3143,
      class: 1,
    },
    {
      label: "Neonatologia grupa ryzyka 3",
      value: 3.314,
      class: 3,
    },
    {
      label: "Neurochirurgia grupa ryzyka 3",
      value: 3.3141,
      class: 3,
    },
    {
      label: "Okulistyka grupa ryzyka 1",
      value: 1.3144,
      class: 1,
    },
    {
      label: "Onkologia i hematologia dziecięca grupa ryzyka 1",
      value: 1.3145,
      class: 1,
    },
    {
      label: "Onkologia kliniczna grupa ryzyka 1",
      value: 1.3146,
      class: 1,
    },
    {
      label: "Ortopedia i traumatologia narządu ruchu grupa ryzyka 3",
      value: 3.3147,
      class: 3,
    },
    {
      label: "Otorynolaryngologia grupa ryzyka 1",
      value: 1.3148,
      class: 1,
    },
    {
      label: "Patomorfologia grupa ryzyka 1",
      value: 1.3149,
      class: 1,
    },
    {
      label: "Pediatria grupa ryzyka 1",
      value: 1.315,
      class: 1,
    },
    {
      label: "Położnictwo i ginekologia grupa ryzyka 3",
      value: 3.3151,
      class: 3,
    },
    {
      label: "Psychiatria grupa ryzyka 1",
      value: 1.3152,
      class: 1,
    },
    {
      label: "Psychiatria dzieci i młodzieży grupa ryzyka 1",
      value: 1.3153,
      class: 1,
    },
    {
      label: "Radiologia i dianostyka obrazowa grupa ryzyka 1",
      value: 1.3154,
      class: 1,
    },
    {
      label: "Radioterapia onkologiczna grupa ryzyka 2",
      value: 2.3155,
      class: 2,
    },
    {
      label: "Rehabilitacja medyczna grupa ryzyka 1",
      value: 1.3156,
      class: 1,
    },
    {
      label: "Reumatologia grupa ryzyka 1",
      value: 1.3157,
      class: 1,
    },
    {
      label: "Seksuologia grupa ryzyka 1",
      value: 1.3158,
      class: 1,
    },
    {
      label: "Toksykologia kliniczna grupa ryzka 1",
      value: 1.3159,
      class: 1,
    },
    {
      label: "Transfuzjologia kliniczna grupa ryzyka 1",
      value: 1.316,
      class: 1,
    },
    {
      label: "Transplantologia kliniczna grupa ryzyka 1",
      value: 1.3161,
      class: 1,
    },
    {
      label: "Urologia grupa ryzyka 3",
      value: 3.3162,
      class: 3,
    },
    {
      label: "Zdrowie publiczne grupa ryzyka 1",
      value: 1.3163,
      class: 1,
    },
    {
      label: "Ginekologia onkologiczna grupa ryzyka 3",
      value: 3.164,
      class: 3,
    },
    {
      label: "Hipertensjologia grupa ryzyka 1",
      value: 1.3165,
      class: 1,
    },
    {
      label: "Neuropatologia grupa ryzyka 1",
      value: 1.3166,
      class: 1,
    },
    {
      label: "Otolaryngologia grupa ryzyka 1",
      value: 1.3167,
      class: 1,
    },
    {
      label: "Urologia dziecięca grupa ryzyka 3",
      value: 3.3168,
      class: 3,
    },
    {
      label: "Inna specjalizacja lekarska grupa ryzyka 1",
      value: 1.319,
      class: 1,
    },
    {
      label: "Stomatolog bez specjalizacji grupa ryzyka 1",
      value: 1.32,
      class: 1,
    },
    {
      label: "Stomatolog-chirurgia stomatologiczna grupa ryzyka 3",
      value: 3.3201,
      class: 3,
    },
    {
      label: "Stomatolog-chirurgia szczękowo–twarzowa grupa ryzyka 3",
      value: 3.3202,
      class: 3,
    },
    {
      label: "Stomatolog-epidemiologia 1 grupa ryzyka",
      value: 1.3203,
      class: 1,
    },
    {
      label: "Stomatolog-ortodoncja grupa ryzyka 2",
      value: 2.3204,
      class: 2,
    },
    {
      label: "Stomatolog-periodontologia grupa ryzyka 2",
      value: 2.3205,
      class: 2,
    },
    {
      label: "Stomatolog-protetyka stomatologiczna grupa ryzyka 2",
      value: 2.3206,
      class: 2,
    },
    {
      label: "Stomatolog-stomatologia dziecięca grupa ryzyka 2",
      value: 2.3207,
      class: 2,
    },
    {
      label: "Stomatolog-stomatologia zachowawcza z endodoncją grupa ryzyka 2",
      value: 2.3208,
      class: 2,
    },
    {
      label: "Stomatolog-zdrowie publiczna grupa ryzyka 1",
      value: 1.3209,
      class: 1,
    },
    {
      label: "Inna specjalizacja lekarza stomatologa grupa ryzyka 1",
      value: 1.329,
      class: 1,
    },
  ],
};
