export const doctorStatus = {
  data: [
    {
      label: "Wykonuje zawód lekarza (dentysty) w formie praktyki lekarskiej.",
      value: false,
    },
    {
      label:
        "Wykonuje zawód lekarza (dentysty) w formie innej niż praktyka lekarska",
      value: true,
    },
  ],
};
